.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.text {
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
    padding-top: 30px;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .text {
        margin-left: 0;
        text-align: center;
        margin-bottom: 70px;
    }
}