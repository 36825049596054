.pageContainer {
    display: grid;
    /* Use CSS Grid for the layout */
    grid-template-columns: 1fr;
    /* Create a single-column layout */
    grid-template-rows: auto 1fr;
    /* Create two rows: one for the logo, one for the main content */
    grid-gap: 1rem;
    /* Add some gap between the rows */
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    align-items: center;
    /* Add this line to align grid items vertically to the center */
}

.mainContent {
    max-width: 1200px;
    /* Set the maximum width for the main content */
    margin: 0 auto;
    /* Add this line to center the main content within the window */
    padding: 1rem;
    /* Add some padding around the main content */
}

@media (max-width: 768px) {
    .pageContainer {
        /* Your mobile styles here */
        display: block;
        padding: 1em;
    }
}