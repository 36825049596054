.logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.footText {
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
    padding-top: 30px;
}